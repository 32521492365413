var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: { "page-title": _vm.exam.title },
      }),
      _vm.loadingExam
        ? _c("div", { attrs: { id: "loading-wrapper" } }, [
            _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
            _c("div", { attrs: { id: "loading-content" } }),
          ])
        : _vm.noContentCondition
        ? _c("no-content-component", {
            attrs: { message: _vm.examDataFetchFailMessage },
          })
        : _c(
            "div",
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { sm: "12", md: "9" } },
                        _vm._l(_vm.arrayForRender, function (item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "ma-10" },
                            [
                              item.questions
                                ? _c(
                                    "div",
                                    [
                                      _c("essay-viewer", {
                                        attrs: { "essay-content": item },
                                      }),
                                      _vm._l(
                                        item.questions,
                                        function (question) {
                                          return _c(
                                            "div",
                                            { key: question.id },
                                            [
                                              _c("question-answer-card-p", {
                                                attrs: {
                                                  submitting:
                                                    _vm.submitting[question.id],
                                                  value:
                                                    _vm.selections[question.id],
                                                  question: question,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.recordAnswer(
                                                      $event,
                                                      question.id,
                                                      question.type
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _c("question-answer-card-p", {
                                    attrs: {
                                      submitting: _vm.submitting[item.id],
                                      value: _vm.selections[item.id],
                                      question: item,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.recordAnswer(
                                          $event,
                                          item.id,
                                          item.type
                                        )
                                      },
                                    },
                                  }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-0 pb-0 hidden-sm-and-down",
                          attrs: { cols: "3" },
                        },
                        [
                          _c("appear-exam-right-box-p", {
                            attrs: {
                              "time-left": _vm.remainingTime,
                              questions: _vm.questions,
                              selections: _vm.selections,
                            },
                            on: {
                              finish: _vm.finishExam,
                              cancel: _vm.cancelExam,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    { staticStyle: { "z-index": "999" }, attrs: { app: "" } },
                    [
                      _c("time-display", {
                        attrs: { value: _vm.remainingTime },
                      }),
                      _c(
                        "v-bottom-sheet",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("keyboard_arrow_down"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3703556110
                          ),
                          model: {
                            value: _vm.sheet,
                            callback: function ($$v) {
                              _vm.sheet = $$v
                            },
                            expression: "sheet",
                          },
                        },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "text-center",
                              attrs: { fullscreen: "" },
                            },
                            [
                              _c("appear-exam-right-box-p", {
                                attrs: {
                                  "time-left": _vm.remainingTime,
                                  questions: _vm.questions,
                                  selections: _vm.selections,
                                },
                                on: {
                                  finish: _vm.finishExam,
                                  cancel: _vm.cancelExam,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", width: "700px" },
                  model: {
                    value: _vm.resultModal,
                    callback: function ($$v) {
                      _vm.resultModal = $$v
                    },
                    expression: "resultModal",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Your Result")]),
                      _c("v-card-text", [
                        _vm.result.is_evaluated
                          ? _c("div", [
                              _c("h5", [
                                _vm._v(
                                  "Answered: " + _vm._s(_vm.result.answered)
                                ),
                              ]),
                              _c("h5", [
                                _vm._v(
                                  "Correct: " + _vm._s(_vm.result.correct)
                                ),
                              ]),
                              _c("h5", [
                                _vm._v("Wrong: " + _vm._s(_vm.result.wrong)),
                              ]),
                              _c("h4", [
                                _vm._v("Score: " + _vm._s(_vm.result.score)),
                              ]),
                            ])
                          : _c("div", [
                              _vm._v(
                                " Congratulations, your exam has been submitted. You can check your result once the teacher has finished grading "
                              ),
                            ]),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: { click: _vm.finish },
                              },
                              [_vm._v("See other exams")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }