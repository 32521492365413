<template>
  <m-container class=" sticky_section pl-2">
    <m-container class="rightBox">
      <time-display :value="timeLeft"></time-display>
      <question-nav-p
        :questions="questions"
        :selections="selections"
      ></question-nav-p>
      <m-container class="mt-3 px-0 start-exam-action">
        <m-container class="pa-0">
          <v-row justify="space-between" class="px-6 ma-0">
            <div class="d-flex flex-row align-center justify-center mb-1">
              <div
                style="background: #0099dc;"
                class="round-counter-nav d-flex align-center justify-center"
              >
                <p class="mb-0">{{ answered }}</p>
              </div>
              <p class="mb-0 ml-2">Answered</p>
            </div>
            <div class="d-flex flex-row align-center justify-center mb-1">
              <div
                style="background: #808080;"
                class="round-counter-nav d-flex align-center justify-center"
              >
                <p class="mb-0">{{ questions.length - answered }}</p>
              </div>
              <p class="mb-0 ml-2">Ignored</p>
            </div>
          </v-row>
          <v-divider class="mt-1"></v-divider>
          <v-container class="d-flex align-center justify-center pb-0">
            <div>
              <!--              <v-row-->
              <!--                ><v-btn-->
              <!--                  rounded-->
              <!--                  outlined-->
              <!--                  depressed-->
              <!--                  color="red accent-3"-->
              <!--                  min-width="145px"-->
              <!--                  @click="$emit('cancel')"-->
              <!--                  >Cancel Exam</v-btn-->
              <!--                ></v-row-->
              <!--              >-->
              <v-row class="mt-3"
                ><v-btn
                  rounded
                  depressed
                  color="primary"
                  min-width="145px"
                  @click="$emit('finish')"
                  >{{ !$language ? "Finish Exam" : "পরীক্ষা শেষ করুন" }}</v-btn
                ></v-row
              >
            </div>
          </v-container>
        </m-container>
      </m-container>
    </m-container>
  </m-container>
</template>

<script>
import QuestionNavP from "#ef/exam/components/QuestionNavP.vue";
import TimeDisplay from "#ef/exam/components/TimeDisplay";
export default {
  name: "AppearExamRightBoxP",
  components: { TimeDisplay, QuestionNavP },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    selections: {
      type: Object,
      default: () => ({}),
    },
    timeLeft: {
      // in seconds
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    answered() {
      let i = 0;
      Object.values(this.selections).forEach(val => {
        if (val.length > 0) i++;
      });
      return i;
      // return Object.values(this.selections).length;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss">
.sticky_section {
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
}
.rightBox {
  max-width: 300px;
  min-height: 500px;
  background: #f1f1f1;
  border-radius: 10px;
}
.round-counter-nav {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  p {
    font-size: 16px !important;
    font-weight: normal;
    color: #ffffff;
  }
}
.start-exam-action {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  p {
    font-size: 14px;
  }
}
</style>
