var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "sticky_section pl-2" },
    [
      _c(
        "m-container",
        { staticClass: "rightBox" },
        [
          _c("time-display", { attrs: { value: _vm.timeLeft } }),
          _c("question-nav-p", {
            attrs: { questions: _vm.questions, selections: _vm.selections },
          }),
          _c(
            "m-container",
            { staticClass: "mt-3 px-0 start-exam-action" },
            [
              _c(
                "m-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "px-6 ma-0",
                      attrs: { justify: "space-between" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row align-center justify-center mb-1",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "round-counter-nav d-flex align-center justify-center",
                              staticStyle: { background: "#0099dc" },
                            },
                            [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(_vm._s(_vm.answered)),
                              ]),
                            ]
                          ),
                          _c("p", { staticClass: "mb-0 ml-2" }, [
                            _vm._v("Answered"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row align-center justify-center mb-1",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "round-counter-nav d-flex align-center justify-center",
                              staticStyle: { background: "#808080" },
                            },
                            [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(
                                  _vm._s(_vm.questions.length - _vm.answered)
                                ),
                              ]),
                            ]
                          ),
                          _c("p", { staticClass: "mb-0 ml-2" }, [
                            _vm._v("Ignored"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("v-divider", { staticClass: "mt-1" }),
                  _c(
                    "v-container",
                    { staticClass: "d-flex align-center justify-center pb-0" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    rounded: "",
                                    depressed: "",
                                    color: "primary",
                                    "min-width": "145px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("finish")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.$language
                                        ? "Finish Exam"
                                        : "পরীক্ষা শেষ করুন"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }