<template>
  <m-container>
    <page-title :page-title="exam.title" class="mt-5"></page-title>
    <div v-if="loadingExam" id="loading-wrapper">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
    <no-content-component
      v-else-if="noContentCondition"
      :message="examDataFetchFailMessage"
    ></no-content-component>
    <div v-else>
      <v-container class="pa-0">
        <v-row>
          <v-col sm="12" md="9" class="py-0">
            <div v-for="item in arrayForRender" :key="item.id" class="ma-10">
              <div v-if="item.questions">
                <essay-viewer :essay-content="item"></essay-viewer>
                <div v-for="question in item.questions" :key="question.id">
                  <question-answer-card-p
                    :submitting="submitting[question.id]"
                    :value="selections[question.id]"
                    :question="question"
                    @input="recordAnswer($event, question.id, question.type)"
                  />
                </div>
              </div>
              <question-answer-card-p
                v-else
                :submitting="submitting[item.id]"
                :value="selections[item.id]"
                :question="item"
                @input="recordAnswer($event, item.id, item.type)"
              />
            </div>
          </v-col>
          <v-col cols="3" class="pl-0 pb-0 hidden-sm-and-down">
            <appear-exam-right-box-p
              :time-left="remainingTime"
              :questions="questions"
              :selections="selections"
              @finish="finishExam"
              @cancel="cancelExam"
            ></appear-exam-right-box-p>
          </v-col>
        </v-row>
      </v-container>
      <v-bottom-navigation
        v-if="$vuetify.breakpoint.smAndDown"
        app
        style="z-index: 999"
      >
        <time-display :value="remainingTime"></time-display>
        <v-bottom-sheet v-model="sheet">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </template>
          <v-sheet class="text-center" fullscreen>
            <appear-exam-right-box-p
              :time-left="remainingTime"
              :questions="questions"
              :selections="selections"
              @finish="finishExam"
              @cancel="cancelExam"
            ></appear-exam-right-box-p>
          </v-sheet>
        </v-bottom-sheet>
      </v-bottom-navigation>
      <v-dialog v-model="resultModal" persistent width="700px">
        <v-card>
          <v-card-title>Your Result</v-card-title>
          <v-card-text>
            <div v-if="result.is_evaluated">
              <h5>Answered: {{ result.answered }}</h5>
              <h5>Correct: {{ result.correct }}</h5>
              <h5>Wrong: {{ result.wrong }}</h5>
              <h4>Score: {{ result.score }}</h4>
            </div>
            <div v-else>
              Congratulations, your exam has been submitted. You can check your
              result once the teacher has finished grading
            </div>
            <div class="d-flex justify-end">
              <v-btn color="primary" @click="finish">See other exams</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </m-container>
</template>

<script>
import QuestionAnswerCardP from "#ef/exam/components/QuestionAnswerCardP.vue";
import AppearExamRightBoxP from "#ef/exam/components/AppearExamRightBoxP.vue";
import TimeDisplay from "#ef/exam/components/TimeDisplay";
import NoContentComponent from "/global/components/NoContentComponent";
import { EquationFinder } from "#ef/exam/mixins";
import { keyBy } from "lodash";
import moment from "moment";
import katex from "katex/dist/katex.mjs";
import EssayViewer from "#ecf/question-bank/components/GroupQuestion/EssayViewer";

const math = /\$([^$]*)\$/g;

export default {
  name: "AppearExam",
  components: {
    EssayViewer,
    TimeDisplay,
    QuestionAnswerCardP,
    AppearExamRightBoxP,
    NoContentComponent
  },
  mixins: [EquationFinder],
  props: {
    studentExamId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingExam: undefined,
      noContentCondition: false,
      questions: [],
      essays: [],
      arrayForRender: [],
      exams: [],
      selections: {},
      remainingTime: 0,
      exam: {},
      result: {},
      resultModal: false,
      sheet: false,
      essayMap: {},
      submitting: {}
    };
  },
  computed: {
    selected() {
      return Object.values(this.selections).length;
    },
    totalQuestions() {
      return Object.values(this.questions).reduce(
        (acc, qs) => acc + qs.length,
        0
      );
    },
    ignored() {
      return this.totalQuestions - this.selected;
    },
    examDataFetchFailMessage() {
      return this.$language
        ? "পরীক্ষা সংক্রান্ত তথ্য নেই"
        : "There is no exam data";
    }
  },
  watch: {
    remainingTime(value) {
      if (value <= 0) {
        this.$root.$emit("alert", ["Info", "Your time is up"]);
        this.finishExam();
      } else if (value === 300) {
        this.$root.$emit("alert", ["Warning", "You only have 5 minutes left"]);
      }
    }
  },
  async created() {
    try {
      this.loadingExam = true;
      await this.loadExam();
      let start_date_time = this.exam.start_at.split("+")[0].replace("T", " ");
      let serverDateTime = await this.$store.dispatch(
        "studentExam/getCurrentTime"
      );
      let current_date_time = serverDateTime.current_time;
      if (this.dateTimeSmaller(current_date_time, start_date_time)) {
        this.remainingTime = this.exam.total_duration * 60;
      } else {
        let diff = moment
          .utc(
            moment(current_date_time, "YYYY-MM-DD HH:mm:ss").diff(
              moment(start_date_time, "YYYY-MM-DD HH:mm:ss")
            )
          )
          .format("HH:mm:ss");
        let totalDurationInSeconds = this.exam.total_duration * 60;
        let diffSplit = diff.split(":");
        let timePassedInSeconds =
          Number(diffSplit[0]) * 3600 +
          Number(diffSplit[1] * 60) +
          Number(diffSplit[2]);
        if (timePassedInSeconds < totalDurationInSeconds) {
          this.remainingTime = totalDurationInSeconds - timePassedInSeconds;
        } else {
          this.remainingTime = 0;
        }
      }

      this.intervalId = setInterval(() => {
        this.remainingTime--;
      }, 1000);
    } catch (e) {
      this.$logger.log(e);
      this.$root.$emit("alert", [undefined, "Failed to initiate exam"]);
    } finally {
      this.loadingExam = false;
    }
  },
  methods: {
    format(text) {
      if (text.includes("$")) {
        return text.replace(math, (match, txt) => {
          const rendered = katex.renderToString(txt, {
            displayMode: true,
            leqno: false,
            fleqn: false,
            throwOnError: false,
            errorColor: "#cc0000",
            strict: "warn",
            macros: { "\\f": "f(#1)" }
          });
          return rendered;
        });
      } else {
        return text;
      }
    },
    dateTimeSmaller(first, second) {
      let firstSplit = first.split(/:|-| /g);
      let secondSplit = second.split(/:|-| /g);
      for (let i = 0; i < firstSplit.length; i++) {
        if (Number(firstSplit[i]) < Number(secondSplit[i])) {
          return true;
        } else if (Number(firstSplit[i]) > Number(secondSplit[i])) {
          return false;
        }
      }
      return true;
    },
    async finish() {
      try {
        this.resultModal = false;
        await this.$router.push({
          name: "exams-student",
          params: {
            course_id: this.$attrs.course_id,
            batch_id: this.exam.batch_id
          }
        });
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async getCurrentServerTime() {
      const currentTime = await this.$store.dispatch(
        "studentExam/getCurrentTime",
        {}
      );
      return currentTime;
    },
    async loadExam() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        this.loadingExam = false;
        this.noContentCondition = true;
        return;
      }
      try {
        const examData = await this.$store.dispatch(
          "studentExam/getStudentExam",
          {
            studentExamId: this.studentExamId
          }
        );
        this.exam = examData.student_exam;
        this.questions = examData.questions || [];
        this.essays = examData.essays || [];
        this.essayMap = keyBy(this.essays, "id");

        await this.retrieveEquationsAndModifyQuestions(
          this.questions,
          this.studentExamId
        );

        this.questions.sort((x, y) => {
          return x.order - y.order;
        });

        for (let question of this.questions) {
          if (question.essay_id) {
            const essay = this.essayMap[question.essay_id];
            if (essay.questions) essay.questions.push(question);
            else {
              this.arrayForRender.push(essay);
              essay.questions = [question];
            }
            question.shouldPick = false;
          } else this.arrayForRender.push(question);
        }
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    selectCategory(categoryId) {
      this.currentCategory = this.categories.filter(
        c => c.id === categoryId
      )[0];
      this.loadQuestions(categoryId);
    },
    async loadQuestions(categoryId) {
      try {
        const questions = await this.getQuestions(categoryId);
        this.$set(this.questions, categoryId, questions);
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, "Failed to Load Questions"]);
      }
    },
    async recordAnswer(answerId, questionId, questionType) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      let answers = this.selections[questionId];
      const answerPayload = {
        studentExamId: this.studentExamId,
        questionId: questionId
      };
      if (questionType === "mcq") {
        if (answers) {
          if (!answers.includes(answerId)) {
            answers.push(answerId);
          } else {
            let index = answers.indexOf(answerId);
            answers.splice(index, 1);
          }
        } else {
          answers = [];
          answers.push(answerId);
        }
        answerPayload.answerId = JSON.stringify(answers);
      } else if (questionType === "fib") {
        answerPayload.answerId = answerId;
        answers = [answerId];
      } else if (questionType === "desc") {
        answerPayload.answerId = {
          value: answerId.value || undefined,
          file: answerId.filename || undefined
        };
        answers = [answerId];
      }
      try {
        this.$set(this.submitting, questionId, true);
        await this.$store.dispatch("studentExam/recordAnswer", answerPayload);
        this.$set(this.selections, questionId, answers);
      } catch (e) {
        this.$root.$emit("alert", [undefined, JSON.parse(e.message).error]);
      } finally {
        this.$set(this.submitting, questionId, false);
      }
    },
    async cancelExam() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      try {
        this.cancelling = true;
        await this.$store.dispatch("studentExam/cancel", {
          studentExamId: this.studentExamId
        });
        clearInterval(this.intervalId);
        await this.$router.go(-2);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.cancelling = false;
      }
    },
    async finishExam() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      try {
        this.finishing = true;
        this.result = await this.$store.dispatch("studentExam/finish", {
          studentExamId: this.studentExamId
        });
        clearInterval(this.intervalId);
        this.resultModal = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.finishing = false;
      }
    }
  }
};
</script>
